import jwtDecode from 'jwt-decode';
import moment from 'moment';

/**
 * Is JWT expired
 *
 * @param {String} token
 * @returns {boolean}
 */
export const isExpired = (token) => {
    // Unix timestamp of token expiration
    const expiration = jwtDecode(token).exp;

    return moment(Date.now()) >= moment.unix(expiration);
};

export const getToken = (token = localStorage.getItem('jwt')) => {
    if (!token || isExpired(token)) return false;

    return token;
};

export const setToken = (token) => localStorage.setItem('jwt', token);
export const removeToken = () => localStorage.removeItem('jwt');
