import React, { Component } from 'react';

import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Loadable from 'react-loadable';
import Loading from './components/Loading';

import './sass/main.scss';
const Home = Loadable({
    loader: () => import('./routes/Home/View'),
    loading: Loading,
});

const Dictionary = Loadable({
    loader: () => import('./routes/Dictionary/View'),
    loading: Loading,
});

const AddWord = Loadable({
    loader: () => import('./routes/AddWord/View'),
    loading: Loading,
});

const Word = Loadable({
    loader: () => import('./routes/Word/ViewContainer'),
    loading: Loading,
});

const Thanks = Loadable({
    loader: () => import('./routes/Thanks/View'),
    loading: Loading,
});

const AboutUs = Loadable({
    loader: () => import('./routes/AboutUs/View'),
    loading: Loading,
});

const Media = Loadable({
    loader: () => import('./routes/Media/View'),
    loading: Loading,
});

const Terms = Loadable({
    loader: () => import('./routes/Terms/View'),
    loading: Loading,
});

const Administration = Loadable({
    loader: () => import('./routes/Administration/View'),
    loading: Loading,
});

const AdministrationWords = Loadable({
    loader: () => import('./routes/Administration/Words/View'),
    loading: Loading,
});

const AdministrationTranslations = Loadable({
    loader: () => import('./routes/Administration/Translations/View'),
    loading: Loading,
});

const AdministrationComments = Loadable({
    loader: () => import('./routes/Administration/Comments/View'),
    loading: Loading,
});

const AdministrationLogout = Loadable({
    loader: () => import('./routes/Administration/Logout'),
    loading: Loading,
});

const PageNotFound = Loadable({
    loader: () => import('./routes/PageNotFound/View'),
    loading: Loading,
});

class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/речник" component={Dictionary} />
                    <Route exact path="/добави-дума" component={AddWord} />
                    <Route exact path="/дума/:name" component={Word} />
                    <Route exact path="/благодарим" component={Thanks} />
                    <Route exact path="/за-нас" component={AboutUs} />
                    <Route exact path="/медиите-за-нас" component={Media} />
                    <Route exact path="/условия" component={Terms} />
                    <Route exact path="/admin" component={Administration} />
                    <Route
                        exact
                        path="/admin/words"
                        component={AdministrationWords}
                    />
                    <Route
                        exact
                        path="/admin/translations"
                        component={AdministrationTranslations}
                    />
                    <Route
                        exact
                        path="/admin/comments"
                        component={AdministrationComments}
                    />
                    <Route
                        exact
                        path="/admin/logout"
                        component={AdministrationLogout}
                    />
                    <Route component={PageNotFound} />
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;
