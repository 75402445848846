import React from 'react';
import { getToken, setToken, removeToken } from './../utils/jwt';
import auth from './../middlewares/auth';

import { ApolloClient } from 'apollo-client';
import { ApolloProvider } from 'react-apollo';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { concat } from 'apollo-link';
import App from '../App';

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_API });

export const UserContext = React.createContext({
    isLoggedIn: false,
    login: () => {},
    logout: () => {},
});

class Context extends React.Component {
    constructor(props) {
        super(props);

        this.setLoggedIn = (isLoggedIn) =>
            this.setState({
                context: {
                    ...this.state.context,
                    isLoggedIn,
                },
            });

        this.login = (token) => {
            setToken(token);
            this.setLoggedIn(true);
        };

        this.logout = () => {
            if (!this.state.context.isLoggedIn) return false;

            removeToken();
            this.setLoggedIn(false);
        };

        this.state = {
            context: {
                isLoggedIn: !!getToken(),
                login: this.login,
                logout: this.logout,
            },
            client: new ApolloClient({
                link: concat(auth(props.fingerprint, this.logout), httpLink),
                cache: new InMemoryCache(),
            }),
        };
    }

    render() {
        const { client, context } = this.state;

        return (
            <UserContext.Provider value={context}>
                <ApolloProvider client={client}>
                    <App />
                </ApolloProvider>
            </UserContext.Provider>
        );
    }
}

export default Context;
