import Fingerprint2 from 'fingerprintjs2';

/**
 * Get Fingerprint value of the user
 *
 * Note: We should not run fingerprinting directly on or after page load. (check fingerprintjs2 issues #307, #254)
 * Because of this, we delay it for a few milliseconds with setTimeout or requestIdleCallback
 * to ensure consistent fingerprints.
 *
 * @returns {Promise}
 */
export const getFingerprint = () => {
    // Fingerprint2 ships with the murmur hash function that you may use to create a hash fingerprint
    // @link https://en.wikipedia.org/wiki/MurmurHash
    const getMurmur = (components) => {
        return Fingerprint2.x64hash128(
            components
                .map(function(pair) {
                    return pair.value;
                })
                .join(),
            31
        );
    };

    return new Promise((resolve) => {
        if (window.requestIdleCallback) {
            requestIdleCallback(() => {
                Fingerprint2.get((components) => {
                    resolve(getMurmur(components));
                });
            });
        } else {
            setTimeout(() => {
                Fingerprint2.get((components) => {
                    resolve(getMurmur(components));
                });
            }, 500);
        }
    });
};
