import { ApolloLink } from 'apollo-link';
import { getToken } from './../utils/jwt';

const auth = (Fingerprint, logout) =>
    new ApolloLink((operation, forward) => {
        const token = getToken();

        if (!token) logout();

        // Add the authorization to the headers
        operation.setContext({
            headers: {
                Fingerprint,
                ...(token && { Authorization: `Bearer ${token}` }),
            },
        });

        return forward(operation);
    });

export default auth;
