import React from 'react';

const Loader = () => (
    <div className="d-flex align-items-center justify-content-center">
        <div className="bouncing-loader mt-3">
            <div />
            <div />
            <div />
        </div>
    </div>
);

export default Loader;
