// This must be the first line in src/index.js
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { getFingerprint } from './utils/fingerprint';

import Context from './components/Context';
import './index.css';

const render = (Fingerprint) => {
    ReactDOM.render(
        <Context fingerprint={Fingerprint} />,
        document.getElementById('root')
    );
};

// We render the APP, only if we have a Fingerprint value.
// Fingerprint generation may take a few milliseconds.
// If these milliseconds are a UI drawback, then we can show a Loading message or something similar.
// Loading functionality can be implemented in the App component.
getFingerprint().then(render);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
